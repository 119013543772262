// extracted by mini-css-extract-plugin
export const article = "main-module--article--2jFsv";
export const headerbox = "main-module--headerbox--SRAzG";
export const header = "main-module--header--3dTIO";
export const grad = "main-module--grad--3vBlZ";
export const arrow = "main-module--arrow--Z1Pyk";
export const textbox = "main-module--textbox--3vp20";
export const row = "main-module--row--Rw5It";
export const h1 = "main-module--h1--2P96v";
export const txt = "main-module--txt--1T3jE";
export const art1 = "main-module--art1--VlUQi";
export const art1svg = "main-module--art1svg--2bXIk";
export const art1bg = "main-module--art1bg--1pF18";
export const art2 = "main-module--art2--14cYR";
export const art2box = "main-module--art2box--AVOzt";
export const leftbox = "main-module--leftbox--2HEUu";
export const leftline = "main-module--leftline--sST2_";
export const mobile = "main-module--mobile--7LblD";
export const rightline = "main-module--rightline--D7ZB3";
export const arr2 = "main-module--arr2---Gx8q";
export const rightbox = "main-module--rightbox--mFUF8";
export const boxval = "main-module--boxval--3-Wl6";
export const boxyear = "main-module--boxyear--1g3OA";
export const artsvg20 = "main-module--artsvg20--1biXx";
export const artsvg21 = "main-module--artsvg21--Z5vrn";
export const textsubbox = "main-module--textsubbox--3qZLX";
export const art3 = "main-module--art3--1Kgh6";
export const art3header = "main-module--art3header--1-dSq";
export const art3vals = "main-module--art3vals--2AiTS";
export const art3valbox = "main-module--art3valbox--3d1tV";
export const art3val = "main-module--art3val--2aKV0";
export const art3bar = "main-module--art3bar--1pIY0";
export const art3year = "main-module--art3year--Obf6F";
export const art3desc = "main-module--art3desc--2_BKU";
export const art4 = "main-module--art4--DXfg-";
export const art4txt = "main-module--art4txt--19eAi";
export const art5 = "main-module--art5--2k8rc";
export const source = "main-module--source--29_8s";
export const row5 = "main-module--row5--VDKhU";
export const artheader = "main-module--artheader--39vF2";
export const artdesc = "main-module--artdesc--1aLVW";
export const valbox = "main-module--valbox--1vh44";
export const valel = "main-module--valel--yHl3g";
export const art5svg = "main-module--art5svg--36pEg";
export const art5txt = "main-module--art5txt--2JQqD";
export const art5txth1 = "main-module--art5txth1--30CFb";
export const quotes = "main-module--quotes--3oHbv";
export const qrow = "main-module--qrow--GFiv0";
export const quotestxt = "main-module--quotestxt--XRAHL";
export const author = "main-module--author--1PbAA";
export const quotessvg = "main-module--quotessvg--3jssu";
export const cars = "main-module--cars--1qu8i";
export const quotes20 = "main-module--quotes20--1LEfs";
export const quotes21 = "main-module--quotes21--1vVMv";
export const footer = "main-module--footer--15HtG";