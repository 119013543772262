import React, { useEffect } from 'react'

import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import * as s from "./main.module.css"
import { withpath } from '../utils/index.js'
import { animateFromOpacity, animateZoomOut } from '../utils/animate.js'
import { animateCars, animateBars, animateArt1, animateArt2, animateArt5 } from '../utils/animate.js'

import Grad from "../assets/grad.svg"
import Arrow from "../assets/arr.svg"
import Arr2 from "../assets/arr2.svg"
import Art1 from "../assets/art1.svg"
import Quotes from "../assets/quotes.svg"
import Cars from "../assets/cars.svg"
import Mobile from "../assets/mobile.svg"
import Quotes20 from "../assets/quotes2_0.svg"
import Quotes21 from "../assets/quotes2_1.svg"

import Art20 from "../assets/art2_0.svg"
import Art21 from "../assets/art2_1.svg"

import Art50 from "../assets/art5_0.svg"
import Art51 from "../assets/art5_1.svg"
import Art52 from "../assets/art5_2.svg"

const isBrowser = typeof window !== "undefined"

const Main = (p) => {

	let data = p.data;

	gsap.registerPlugin(ScrollTrigger);
	gsap.core.globals("ScrollTrigger", ScrollTrigger);
	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
	});
	gsap.defaults({overwrite: 'auto'});

	// START & LOOP ANIMATIONS
	useEffect(() => {
		const arrow = document.querySelector(`#headerarrow`);
		gsap.to(arrow, {y:10, duration:1, repeat: -1, yoyo: true, ease: `power1.out`})


		// const quotes20 = document.querySelector(`#quotes20`);
		// gsap.to(quotes20, {y:10, duration:2 + 0.3 * Math.random(), repeat: -1, yoyo: true, ease: `power1.out`})

		// const quotes21 = document.querySelector(`#quotes21`);
		// gsap.to(quotes21, {y:10, duration:2, repeat: -1, yoyo: true, ease: `power1.out`})
	},[])

	// FADE IN ANIMATIONS
	useEffect(() => {

		animateFromOpacity(
			gsap.utils.toArray([
				`.${s.headerbox}`, 
				`.${s.h1}`, 
				`.${s.txt}`, 
				`.${s.art1}`,
				`.${s.art2}`,
				`.${s.art3}`,
				`.${s.art4}`,
				`.${s.art5}`,
				`.${s.quotes}`,
				`.${s.footer}`,
			])
		);

		animateZoomOut(
			gsap.utils.toArray([
				`.${s.quotessvg}`
			])
		);

		animateCars();

		let bars = gsap.utils.toArray([`.${s.art3bar}`])
		let vals = gsap.utils.toArray([`.${s.art3val}`])
		animateBars(bars, vals);




		animateArt1()


		animateArt2(
			gsap.utils.toArray([`.${s.boxval}`]),
			gsap.utils.toArray([`.${s.leftline}`]),
			gsap.utils.toArray([`.${s.mobile}`]),
			gsap.utils.toArray([`.${s.rightline}`])
			// gsap.utils.toArray([`.${s.rightbox}`])
		)





		const valels = gsap.utils.toArray([`.${s.valel}`])
		const art5txt = gsap.utils.toArray([`.${s.art5txt}`])
		const art5svg = gsap.utils.toArray([`.${s.art5svg}`])

		animateArt5(valels, art5txt, art5svg);


		if(isBrowser){ window.addEventListener("resize", onResize);}
		setTimeout(onResize, 500)
		setTimeout(onResize, 1500)

	},[])

	function onResize(){
		ScrollTrigger.refresh(true);
		ScrollTrigger.update();
	}

	return (
		<section className={s.article} id={`article`} >
			
			<div className={s.headerbox} style={{backgroundImage: `url(${withpath(`img/bg.jpg`)})` }}>
				<div className={s.grad}><Grad /></div>
				<div className={s.arrow} id={`headerarrow`}><Arrow /></div>
				<div className={s.header} dangerouslySetInnerHTML={{__html: data.header }} />
			</div>

			<div className={s.textbox}>
				<div className={s.row}>
					<p className={s.h1} dangerouslySetInnerHTML={{__html: data.subheader }} />
					<p className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} />
					<p className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[1] }} />
				</div>
			</div>

			<div className={s.art1}>
				<div className={s.row}>
					<img className={s.art1bg} src={withpath(`img/art1.jpg`)} alt=""/>
					<div className={s.artheader} dangerouslySetInnerHTML={{__html: data.art1.header }} />
					<div className={s.artdesc} dangerouslySetInnerHTML={{__html: data.art1.desc }} />
					<div className={s.art1svg} id={`art1box`}><Art1 /></div>
					<div className={s.source} dangerouslySetInnerHTML={{__html: data.art1.source }} />
				</div>
			</div>

			<div className={s.textbox}>
				<div className={s.row}>
					<p className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[2] }} />

					<p className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[4] }} />
					<p className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[5] }} />
				</div>
			</div>

			<div className={s.quotes} data-id={1}>
				<div className={s.qrow} data-id={1}>
					<div className={s.quotessvg}><Quotes /></div>
					<div className={s.cars}><Cars /></div>
					<p className={s.quotestxt} data-id={1} dangerouslySetInnerHTML={{__html: data.quote1.txt }} />
					<div className={s.author} dangerouslySetInnerHTML={{__html: data.quote1.author }} />
				</div>
			</div>

			<div className={s.textbox}>
				<div className={s.row}>
					<p className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[7] }} />
				</div>
			</div>

			<div className={s.art2} id={`art2box`}>
				<div className={s.row}>
					<div className={s.artheader} data-id={2} dangerouslySetInnerHTML={{__html: data.art2.header }} />

					<div className={s.art2box}>
						<div className={s.artsvg20}><Art20 /></div>
						<div className={s.artsvg21}><Art21 /></div>

						<div className={s.leftbox}>
							<div className={s.boxval} dangerouslySetInnerHTML={{__html: data.art2.vals[0].val }} />
							<div className={s.boxyear} dangerouslySetInnerHTML={{__html: data.art2.vals[0].year }} />
						</div>
						<div className={s.leftline} />
						<div className={s.mobile}><Mobile /></div>
						<div className={s.rightline}>
							<div className={s.arr2}>
								<Arr2 />
							</div>
						</div>
						<div className={s.rightbox}>
							<div className={s.boxval} dangerouslySetInnerHTML={{__html: data.art2.vals[1].val }} />
							<div className={s.boxyear} dangerouslySetInnerHTML={{__html: data.art2.vals[1].year }} />
						</div>
					</div>


					<div className={s.artdesc} data-id={2} dangerouslySetInnerHTML={{__html: data.art2.desc }} />
					<div className={s.source} dangerouslySetInnerHTML={{__html: data.art2.source }} />
				</div>
			</div>

			<div className={s.textbox}>
				<div className={s.row}>
					<div className={s.textsubbox} data-id={8}>
						<p className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[8] }} />
						<div className={s.art4}>
							<div className={s.art4txt} dangerouslySetInnerHTML={{__html: data.art4.header }} />
							<div className={s.source} dangerouslySetInnerHTML={{__html: data.art4.source }} />
						</div>
					</div>
					<p className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[9] }} />
					
					<div className={s.textsubbox} data-id={10}>
						<p className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[10] }} />
						<div className={s.art3}>
							<div className={s.art3header} dangerouslySetInnerHTML={{__html: data.art3.header }} />
							
							<div className={s.art3vals} id={`art3`}>
								<div className={s.art3valbox} data-id={0}>
									<div className={s.art3val} data-id={0} dangerouslySetInnerHTML={{__html: data.art3.vals[0].val }} />
									<div className={s.art3bar} data-id={0} />
									<div className={s.art3year} dangerouslySetInnerHTML={{__html: data.art3.vals[0].year }} />
								</div>
								<div className={s.art3valbox} data-id={1}>
									<div className={s.art3val} data-id={1} dangerouslySetInnerHTML={{__html: data.art3.vals[1].val }} />
									<div className={s.art3bar} data-id={1} />
									<div className={s.art3year} dangerouslySetInnerHTML={{__html: data.art3.vals[1].year }} />
								</div>


							</div>
							<div className={s.art3desc} dangerouslySetInnerHTML={{__html: data.art3.desc }} />
							<div className={s.source} dangerouslySetInnerHTML={{__html: data.art3.source }} />
						</div>
					</div>
				</div>
			</div>

			<div className={s.quotes} data-id={2}>
				<div className={s.qrow} data-id={2}>
					<div className={s.quotes20} id={`quotes20`}><Quotes20 /></div>
					<div className={s.quotes21} id={`quotes21`}><Quotes21 /></div>
					<p className={s.quotestxt} data-id={2} dangerouslySetInnerHTML={{__html: data.quote2.txt }} />
				</div>
			</div>

			<div className={s.textbox}>
				<div className={s.row}>
					<p className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[11] }} />
				</div>
			</div>

			<div className={s.art5}>
				<div className={s.row5}>
					<div className={s.artheader} data-id={5} dangerouslySetInnerHTML={{__html: data.art5.header }} />
					<div className={s.valbox}>
						<div className={s.valel} data-id={0}>
							<div className={s.art5svg}><Art50 /></div>
							<div className={s.art5txt} >
								<div className={s.art5txth1} dangerouslySetInnerHTML={{__html: data.art5.h1[0] }} />
								<div className={s.art5txtv1} dangerouslySetInnerHTML={{__html: data.art5.vals[0] }} />
							</div>
						</div>
						<div className={s.valel} data-id={1}>
							<div className={s.art5svg}><Art51 /></div>
							<div className={s.art5txt} >
								<div className={s.art5txth1} dangerouslySetInnerHTML={{__html: data.art5.h1[1] }} />
								<div className={s.art5txtv1} dangerouslySetInnerHTML={{__html: data.art5.vals[1] }} />
							</div>
						</div>
						<div className={s.valel} data-id={2}>
							<div className={s.art5svg}><Art52 /></div>
							<div className={s.art5txt} >
								<div className={s.art5txth1} dangerouslySetInnerHTML={{__html: data.art5.h1[2] }} />
								<div className={s.art5txtv1} dangerouslySetInnerHTML={{__html: data.art5.vals[2] }} />
							</div>
						</div>
					</div>
					<div className={s.source} dangerouslySetInnerHTML={{__html: data.art5.source }} />
				</div>
			</div>

			<div className={s.textbox}>
				<div className={s.row}>
					<p className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[13] }} />
					<p className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[14] }} />
				</div>
			</div>

			<div className={s.footer} />

		</section>
)}

export default Main