import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";





export function animateFromOpacity(arr){
	gsap.set(arr, {opacity: 0});
	arr.forEach((d, i)=> {
		gsap.timeline({
			scrollTrigger: {
				invalidateOnRefresh: true,
				id: `tl_op`+i,
				trigger: d,
				start: () => "top bottom-=120",
				end: () => "bottom top",
				scrub: 0,
				onEnter: (self)=>{
					gsap.to(d, {duration: 1.5, opacity:1, ease:`power2.out`});
					self.disable();
				}
			}
		});
	});
}

export function animateZoomOut(arr){
	gsap.set(arr, {opacity: 0, transformOrigin: `center center`, scale: 1.2});
	arr.forEach((d, i)=> {
		gsap.timeline({
			scrollTrigger: {
				invalidateOnRefresh: true,
				id: `tl_zo`+i,
				trigger: d,
				start: () => "top bottom-=120",
				end: () => "bottom top",
				scrub: 0,
				onEnter: (self)=>{
					gsap.to(d, {duration: 1.5, opacity:1, scale: 1, ease:`power2.out`});
					self.disable();
				}
			}
		});
	});
}




///////

export function animateCars(){
	let clip = gsap.utils.toArray([`#carscliprect`])[0];
	let cars = gsap.utils.toArray([`.carscircle`]);

	gsap.set(clip, {transformOrigin: `left center`, scaleX:0})
	gsap.set(cars, {transformOrigin: `center center`, scale:0.5, opacity: 0})
	
	gsap.timeline({
		scrollTrigger: {
			invalidateOnRefresh: true,
			trigger: `#cars`,
			start: () => "top bottom-=120",
			end: () => "bottom top",
			scrub: 0,
			onEnter: (self)=>{
				gsap.to(clip, {duration: 2.5, scaleX: 1, ease:`power2.out`});
				gsap.to(cars, {duration: 1.5, opacity:1, scale: 1, ease:`power2.out`, stagger: 0.1});
				// self.disable();
			}
		}
	});

	gsap.timeline({
		scrollTrigger: {
			invalidateOnRefresh: true,
			trigger: `#cars`,
			start: () => "top bottom-=0",
			end: () => "bottom top",
			scrub: 0,
			onLeaveBack: (self)=>{
				gsap.set(clip, {transformOrigin: `left center`, scaleX:0})
				gsap.set(cars, {transformOrigin: `center center`, scale:0.5, opacity: 0})
			}
		}
	});
}

export function animateBars(bars, vals){
	
	gsap.set(bars, {transformOrigin: `center bottom`, scaleY:0})
	gsap.set(vals, {opacity:0})

	gsap.timeline({
		scrollTrigger: {
			invalidateOnRefresh: true,
			trigger: `#art3`,
			start: () => "top bottom-=120",
			end: () => "bottom top",
			scrub: 0,
			onEnter: (self)=>{
				gsap.killTweensOf([bars, vals])
				gsap.to(bars, {duration: 1.5, scaleY: 1, ease:`power2.out`, stagger: 0.3});
				gsap.to(vals, {duration: 1.5, opacity: 1, ease:`power2.out`, stagger: 0.3, delay: 1.1});
				// self.disable();
			}
		}
	});

	gsap.timeline({
		scrollTrigger: {
			invalidateOnRefresh: true,
			trigger: `#art3`,
			start: () => "top bottom-=0",
			end: () => "bottom top",
			scrub: 0,
			onLeaveBack: (self)=>{
				gsap.killTweensOf([bars, vals])
				gsap.set(bars, {transformOrigin: `center bottom`, scaleY:0})
				gsap.set(vals, {opacity:0})
				
			}
		}
	});
}

export function animateArt2(boxval, leftline, mobile, rightline){


	const man1 = gsap.utils.toArray([`.art2man`]);
	const man2 = gsap.utils.toArray([`.art2man2`]);
	gsap.set(man1, {transformOrigin: `center center`, opacity: 0, scale: 0.5})
	gsap.set(man2, {transformOrigin: `center center`, opacity: 0, scale: 0.5})

	gsap.set(boxval, {transformOrigin: `center center`, opacity: 0, y: 10})
	gsap.set(leftline, {transformOrigin: `center center`, scaleX:0.5, x: -10, opacity: 0})
	gsap.set(mobile, {transformOrigin: `center center`, scale:0.5, opacity: 0})
	gsap.set(rightline, {transformOrigin: `center center`, scaleX:0.5, x: 0, opacity: 0})

	gsap.timeline({
		scrollTrigger: {
			invalidateOnRefresh: true,
			trigger: `#art2box`,
			start: () => "top bottom-=120",
			end: () => "bottom top",
			scrub: 0,
			onEnter: (self)=>{
				gsap.to(boxval[0], {delay: 0.2, duration: 1.5, ease:`power2.out`, opacity: 1, y:0})
				gsap.to(leftline, {delay: 0.4, duration: 1.2, ease:`power2.out`, scaleX:1, x: 0, opacity: 1})
				gsap.to(mobile, {delay: 0.4, duration: 1.2, ease:`power2.out`, scale:1, opacity: 1})
				gsap.to(rightline, {delay: 0.6, duration: 1.2, ease:`power2.out`, scaleX:1, x: 0, opacity: 1})
				gsap.to(boxval[1], {delay: 0.8, duration: 1.5, ease:`power2.out`, opacity: 1, y:0})

				gsap.to(man1, {delay: 0.2, duration: 1.5, ease:`power2.out`, opacity: 1, scale: 1, stagger: 0.1})
				gsap.to(man2, {delay: 0.6, duration: 1.5, ease:`power2.out`, opacity: 1, scale: 1, stagger: 0.1})

				// self.disable();
			}
		}
	});

	gsap.timeline({
		scrollTrigger: {
			invalidateOnRefresh: true,
			trigger: `#art2box`,
			start: () => "top bottom-=0",
			end: () => "bottom top",
			scrub: 0,
			onLeaveBack: (self)=>{

				gsap.killTweensOf([boxval, leftline, mobile, rightline, man1, man2])
				gsap.set(boxval, {transformOrigin: `center center`, opacity: 0, y:10})
				gsap.set(leftline, {transformOrigin: `center center`, scaleX:0.5, x: 0, opacity: 0})
				gsap.set(mobile, {transformOrigin: `center center`, scale:0.5, opacity: 0})
				gsap.set(rightline, {transformOrigin: `center center`, scaleX:0.5, x: 0, opacity: 0})
				
				gsap.set(man1, {transformOrigin: `center center`, opacity: 0, scale: 0.5})
				gsap.set(man2, {transformOrigin: `center center`, opacity: 0, scale: 0.5})
			}
		}
	});
}

export function animateArt1(){

	let art1arrow0 = gsap.utils.toArray([`#art1arrow0`]);
	let art1arrow1 = gsap.utils.toArray([`#art1arrow1`]);

	let art1val0 = document.querySelector(`#art1val0 tspan`);
	let art1val1 = document.querySelector(`#art1val1 tspan`);
	let art15g = gsap.utils.toArray([`#art15g`]);

	art1val0.textContent  = 0 + `%`
	art1val1.textContent  = 0 + `%`

	let counter0 = { var: 0 };
	let counter1 = { var: 0 };

	gsap.set(art1arrow0, {transformOrigin: `center center`, rotate: -180, opacity: 0})
	gsap.set(art1arrow1, {transformOrigin: `center center`, rotate: -180})

	gsap.set(art15g, {opacity: 0, transformOrigin: `center center`, scale: 1})


	gsap.timeline({
		scrollTrigger: {
			invalidateOnRefresh: true,
			trigger: `#art1box`,
			start: () => "top bottom-=120",
			end: () => "bottom top",
			scrub: 0,
			onEnter: (self)=>{

				gsap.to(art1arrow1, {duration: 2, ease: `power1.out`, rotate: 0})
				gsap.to(art1arrow0, {duration: 2, ease: `power1.out`, rotate: 0, delay: 2})
				
				gsap.to(art15g, {duration: 1.5, ease: `power1.out`, opacity: 1, scale: 1})

				gsap.to(counter0, { var: 25, duration: 2, ease: "power1.out", 
					onUpdate: function () {
						art1val0.textContent  = Math.round(counter0.var) + `%`
					}
				});
				gsap.to(counter1, { var: 75, duration: 4, ease: "power1.out", 
					onUpdate: function () {
						art1val1.textContent  = Math.round(counter1.var) + `%`
					}
				});

				// self.disable();
			}
		}
	});

	gsap.timeline({
		scrollTrigger: {
			invalidateOnRefresh: true,
			trigger: `#art1box`,
			start: () => "top bottom-=0",
			end: () => "bottom top",
			scrub: 0,
			onLeaveBack: (self)=>{

	gsap.set(art1arrow0, {rotate: -180})
	gsap.set(art1arrow1, {rotate: -180})


				gsap.killTweensOf([art15g, counter0, counter1])

				gsap.set(art15g, {opacity: 0, transformOrigin: `center center`, scale: 1})

				art1val0.textContent = 0 + `%`
				art1val1.textContent = 0 + `%`

				counter0.var = 0;
				counter1.var = 0;
			}
		}
	});




}

export function animateArt5(valels, art5txt, art5svg){

	gsap.set(art5txt, {opacity: 0, x: -10})


	const art50 = gsap.utils.toArray([`.art50`])
	gsap.set(art50, {x: 10, opacity: 0})

	const art51cube0 = gsap.utils.toArray([`#art51cube0`])
	const art51arrow = gsap.utils.toArray([`#art51arrow`])
	const art51cube1 = gsap.utils.toArray([`#art51cube1`])
	gsap.set(art51cube0, {scale: 0.5, opacity: 0, transformOrigin: `center center`})
	gsap.set(art51arrow, {x: -10, scaleX: 0.5, opacity: 0, transformOrigin: `center center`})
	gsap.set(art51cube1, {scale: 0.5, opacity: 0, transformOrigin: `center center`})


	const art52main = gsap.utils.toArray([`#art52main`])
	const art52line = gsap.utils.toArray([`.art52line`])
	const art52object = gsap.utils.toArray([`.art52object`])
	gsap.set(art52main, {scale: 0.5, opacity: 0, transformOrigin: `center center`})
	gsap.set(art52line, {scale: 1, opacity: 0, transformOrigin: `center center`})
	gsap.set(art52object, {scale: 0.5, opacity: 0, transformOrigin: `center center`})

	valels.forEach((d, i)=> {

		gsap.timeline({
			scrollTrigger: {
				invalidateOnRefresh: true,
				trigger: d,
				start: () => "top bottom-=120",
				end: () => "bottom top",
				scrub: 0,
				onEnter: (self)=>{
					gsap.to(art5txt[i], {duration: 1.5, opacity:1, x: 0, ease:`power1.out`});
					// self.disable();

					if(i===0){
						gsap.to(art50, {duration: 1.0, opacity:1, x: 0, ease:`power2.out`, stagger: 0.2});
					}

					if(i===1){
						gsap.to(art51cube0, {duration: 1.2, opacity:1, scale: 1, ease:`power2.out`, delay: 0});
						gsap.to(art51arrow, {duration: 1.0, opacity:1, x: 0, scaleX: 1, ease:`power2.out`, delay: 0.15});
						gsap.to(art51cube1, {duration: 1.2, opacity:1, scale: 1, ease:`power2.out`, delay: 0.3});
					}

					if(i===2){
						gsap.to(art52main, {duration: 1.2, opacity:1, scale: 1, ease:`power2.out`, delay: 0});
						gsap.to(art52line, {duration: 1.2, opacity:1, scale: 1, ease:`power2.out`, delay: 0.15});
						gsap.to(art52object, {duration: 1.2, opacity:1, scale: 1, ease:`power2.out`, delay: 0.3, stagger: 0.1});
					}

				}
			}
		});
	
		gsap.timeline({
			scrollTrigger: {
				invalidateOnRefresh: true,
				trigger: d,
				start: () => "top bottom-=0",
				end: () => "bottom top",
				scrub: 0,
				onLeaveBack: (self)=>{
					// gsap.set(art5txt[i], {opacity:0, x: -10});

					if(i===0){
						gsap.set(art50, {x: 10, opacity: 0})
					}
					if(i===1){
						gsap.set(art51cube0, {scale: 0.5, opacity: 0, transformOrigin: `center center`})
						gsap.set(art51arrow, {x: -10, scaleX: 0.5, opacity: 0, transformOrigin: `center center`})
						gsap.set(art51cube1, {scale: 0.5, opacity: 0, transformOrigin: `center center`})
					}
					if(i===2){
						gsap.set(art52main, {scale: 0.5, opacity: 0, transformOrigin: `center center`})
						gsap.set(art52line, {scale: 1, opacity: 0, transformOrigin: `center center`})
						gsap.set(art52object, {scale: 0.5, opacity: 0, transformOrigin: `center center`})
					}
				}
			}
		});

	});
}